import React, { useContext } from "react";
import { inBrowser } from "./utils";
import IsomorphicClerk from "./isomorphicClerk";
import {
  noProviderError,
  noGuaranteeError,
  noGuaranteedUserError,
} from "./errors";
import {
  ClerkContextType,
  ClerkProviderProps,
  ClerkProviderState,
  IsomorphicClerkI,
  ListenerEmission,
  LoadedClerkI,
  MountProps,
  SignInProps,
  SignUpProps,
  UserButtonProps,
  UserProfileProps,
  UserResource,
  WithClerkProp,
} from "./types";

export * from "./types";
export {
  ClerkContext,
  ClerkProvider,
  SignIn,
  SignUp,
  UserProfile,
  UserButton,
  useClerk,
  withClerk,
  WithClerk,
  useUser,
  withUser,
  WithUser,
  SignedIn,
  SignedOut,
  ClerkLoaded,
};

const ClerkContext = React.createContext<ClerkContextType | undefined>(
  undefined
);
ClerkContext.displayName = "ClerkContext";

class ClerkProvider extends React.PureComponent<
  ClerkProviderProps,
  ClerkProviderState
> {
  private clerk: IsomorphicClerkI;

  constructor(props: ClerkProviderProps) {
    super(props);

    const { frontendApi = "", Clerk: ClerkConstructor, ...rest } = props;

    this.clerk = new IsomorphicClerk(frontendApi, rest, ClerkConstructor);
    this.state = {
      client: undefined,
      session: undefined,
      user: undefined,
    };
  }

  loadClerk() {
    this.clerk.loadClerkJS().then(() => {
      this.setState({
        client: this.clerk.client,
        session: this.clerk.session,
        user: this.clerk.user,
      });
    });
  }

  onClerkEvent = ({ client, session, user }: ListenerEmission) => {
    this.setState({
      client: client,
      session: session,
      user: user,
    });
  };

  componentDidMount() {
    this.loadClerk();

    if (this.clerk) {
      this.clerk.addListener(this.onClerkEvent);
    }
  }

  defaultInitialUser(client: any) {
    const session = client.sessions.find((x: any) => x.status === "active");
    return session ? session.user : null;
  }

  render() {
    const { session } = this.state;
    return (
      <ClerkContext.Provider
        value={{
          guaranteedLoaded: false,
          guaranteedUser: false,
          clerk: this.clerk,
        }}
      >
        {this.clerk instanceof IsomorphicClerk && this.clerk.ssrData && (
          <script
            type="application/json"
            data-clerk="SSR"
            dangerouslySetInnerHTML={{
              __html: this.clerk.ssrData,
            }}
          />
        )}
        <React.Fragment key={session ? session.id : "no-usrses"}>
          {this.props.children}
        </React.Fragment>
      </ClerkContext.Provider>
    );
  }
}

const useClerk = () => {
  const ctx = useContext(ClerkContext);
  if (!ctx) {
    throw new Error(noProviderError);
  } else if (ctx.guaranteedLoaded) {
    return ctx.clerk as LoadedClerkI;
  } else {
    throw new Error(noGuaranteeError);
  }
};

const withClerk = <P extends { clerk: LoadedClerkI }>(
  Component: React.ComponentType<P>,
  displayName?: string
) => {
  displayName =
    displayName || Component.displayName || Component.name || "Component";
  Component.displayName = displayName;
  const HOC: React.FC<Omit<P, "clerk">> = (props: Omit<P, "clerk">) => {
    const ctx = useContext(ClerkContext);
    if (!ctx) {
      if (inBrowser()) {
        throw new Error(noProviderError);
      } else {
        return null;
      }
    } else if (ctx.clerk && typeof ctx.clerk.session === "undefined") {
      return null;
    } else if (ctx.guaranteedLoaded) {
      return <Component {...(props as P)} clerk={ctx.clerk as LoadedClerkI} />;
    } else if (ctx.clerk.client) {
      return (
        <ClerkContext.Provider value={{ ...ctx, guaranteedLoaded: true }}>
          <Component {...(props as P)} clerk={ctx.clerk as LoadedClerkI} />
        </ClerkContext.Provider>
      );
    } else {
      return null;
    }
  };
  HOC.displayName = `withClerk(${displayName})`;
  return HOC;
};

const WithClerk: React.FC<{
  children: (clerk: LoadedClerkI) => React.ReactNode;
}> = ({ children }) => (
  <ClerkContext.Consumer>
    {(ctx) => {
      if (typeof children === "function") {
        if (!ctx) {
          throw new Error(noProviderError);
        } else if (ctx.clerk && typeof ctx.clerk.session === "undefined") {
          return null;
        } else if (ctx.guaranteedLoaded) {
          return children(ctx.clerk as LoadedClerkI);
        } else if (ctx.clerk.client) {
          return (
            <ClerkContext.Provider value={{ ...ctx, guaranteedLoaded: true }}>
              {children(ctx.clerk as LoadedClerkI)}
            </ClerkContext.Provider>
          );
        } else {
          return null;
        }
      } else {
        throw new Error("Clerk: Child of WithClerk must be a function.");
      }
    }}
  </ClerkContext.Consumer>
);

const useUser = () => {
  const ctx = useContext(ClerkContext);
  if (!ctx) {
    throw new Error(noProviderError);
  } else if (ctx.guaranteedUser && ctx.clerk.session) {
    return ctx.clerk.session.user as UserResource;
  } else {
    throw new Error(noGuaranteedUserError);
  }
};

const withUser = <P extends { user: UserResource }>(
  Component: React.ComponentType<P>,
  displayName?: string
) => {
  displayName =
    displayName || Component.displayName || Component.name || "Component";
  Component.displayName = displayName;
  const HOC: React.FC<Omit<P, "user">> = (props: Omit<P, "user">) => {
    const ctx = useContext(ClerkContext);
    if (!ctx) {
      if (inBrowser()) {
        throw new Error(noProviderError);
      } else {
        return null;
      }
    } else if (ctx.guaranteedUser && ctx.clerk.session) {
      return <Component {...(props as P)} user={ctx.clerk.session.user} />;
    } else if (ctx.clerk.client && ctx.clerk.session) {
      return (
        <ClerkContext.Provider
          value={{ ...ctx, guaranteedLoaded: true, guaranteedUser: true }}
        >
          <Component {...(props as P)} user={ctx.clerk.session.user} />
        </ClerkContext.Provider>
      );
    } else {
      return null;
    }
  };
  HOC.displayName = `withUser(${displayName})`;
  return HOC;
};

const WithUser: React.FC<{
  children: (user: UserResource) => React.ReactNode;
}> = ({ children }) => (
  <ClerkContext.Consumer>
    {(ctx) => {
      if (typeof children === "function") {
        if (!ctx) {
          throw new Error(noProviderError);
        } else if (ctx.guaranteedUser && ctx.clerk.session) {
          return children(ctx.clerk.session.user);
        } else if (ctx.clerk.client && ctx.clerk.session) {
          return (
            <ClerkContext.Provider
              value={{ ...ctx, guaranteedLoaded: true, guaranteedUser: true }}
            >
              {children(ctx.clerk.session.user)}
            </ClerkContext.Provider>
          );
        } else {
          return null;
        }
      } else {
        throw new Error("Clerk: Child of WithClerk must be a function.");
      }
    }}
  </ClerkContext.Consumer>
);

class Portal extends React.PureComponent<MountProps, {}> {
  private portalRef = React.createRef<HTMLDivElement>();
  componentDidMount() {
    if (this.portalRef.current) {
      this.props.mount(this.portalRef.current, this.props.props);
    }
  }
  componentWillUnmount() {
    if (this.portalRef.current) {
      this.props.unmount(this.portalRef.current);
    }
  }
  render() {
    return <div ref={this.portalRef} />;
  }
}

const SignIn: React.FC<SignInProps> = withClerk(
  ({ clerk, ...props }: WithClerkProp<SignInProps>) => {
    return (
      <Portal
        mount={clerk.mountSignIn}
        unmount={clerk.unmountSignIn}
        props={props}
      />
    );
  },
  "SignIn"
);

const SignUp: React.FC<SignUpProps> = withClerk(
  ({ clerk, ...props }: WithClerkProp<SignUpProps>) => {
    return (
      <Portal
        mount={clerk.mountSignUp}
        unmount={clerk.unmountSignUp}
        props={props}
      />
    );
  },
  "SignUp"
);

const UserProfile: React.FC<UserProfileProps> = withClerk(
  ({ clerk, ...props }: WithClerkProp<UserProfileProps>) => {
    return (
      <Portal
        mount={clerk.mountUserProfile}
        unmount={clerk.unmountUserProfile}
        props={props}
      />
    );
  },
  "UserProfile"
);

const UserButton: React.FC<UserButtonProps> = withClerk(
  ({ clerk, ...props }: WithClerkProp<UserButtonProps>) => {
    return (
      <Portal
        mount={clerk.mountUserButton}
        unmount={clerk.unmountUserButton}
        props={props}
      />
    );
  },
  "UserButton"
);

const SignedIn: React.FC = withUser(({ children }) => {
  return <>{children}</>;
}, "SignedIn");

const SignedOut: React.FC = withClerk(({ children, clerk }) => {
  return clerk.session === null ? <>{children}</> : null;
}, "SignedOut");

const ClerkLoaded: React.FC = withClerk(({ children }) => {
  return <>{children}</>;
}, "ClerkLoaded");
