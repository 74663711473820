import { version } from "../../package.json";

const FAILED_TO_LOAD_ERROR: string = "Clerk: Failed to load Clerk";
const MISSING_PROVIDER_ERROR: string = "Clerk: Missing provider";
const MISSING_BODY_ERROR: string = "Clerk: Missing <body> element.";

function getScriptSrc(localScriptSrc: string | undefined): string {
  if (localScriptSrc) {
    return localScriptSrc;
  }
  const majorVersion = parseInt(version.split(".")[0], 10);
  return `https://cdn.jsdelivr.net/npm/@clerk/clerk-js@${majorVersion}/dist/clerk.browser.js`;
}

export default function loadScript(
  frontendApi: string,
  localScriptSrc: string | undefined
): Promise<HTMLScriptElement | null> {
  return new Promise((resolve, reject) => {
    if (window.Clerk) {
      resolve(null);
    }

    if (!frontendApi) {
      reject(MISSING_PROVIDER_ERROR);
    }

    const script = document.createElement("script");
    const src = getScriptSrc(localScriptSrc);

    script.setAttribute("data-clerk-frontend-api", frontendApi);
    script.setAttribute("crossorigin", "anonymous");
    script.async = true;

    if (!document.body) {
      reject(MISSING_BODY_ERROR);
    }

    script.addEventListener("load", () => resolve(script));
    script.addEventListener("error", () => reject(FAILED_TO_LOAD_ERROR));

    script.src = src;
    document.body.appendChild(script);
  });
}
