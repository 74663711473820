import { inBrowser, loadScript } from "./utils";
import { noFrontendApiError } from "./errors";
import {
  BrowserClerkConstructor,
  BrowserClerkI,
  ClerkOptionsI,
  ClientResource,
  IsomorphicClerkI,
  ListenerEmission,
  SessionResource,
  SignInProps,
  SignUpProps,
  UserButtonProps,
  UserProfileProps,
} from "./types";

export default class IsomorphicClerk implements IsomorphicClerkI {
  private mode: string;
  private frontendApi: string;
  private options: ClerkOptionsI;
  private ClerkConstructor: any;
  private listeners: Array<(emission: ListenerEmission) => void> = [];
  private clerkjs: BrowserClerkI | null = null;
  private preopenSignIn?: null | SignInProps = null;
  private preopenSignUp?: null | SignUpProps = null;
  private premountSignInNodes = new Map<HTMLDivElement, SignInProps>();
  private premountSignUpNodes = new Map<HTMLDivElement, SignUpProps>();
  private premountUserProfileNodes = new Map<
    HTMLDivElement,
    UserProfileProps
  >();
  private premountUserButtonNodes = new Map<HTMLDivElement, UserButtonProps>();

  private _loaded = false;

  ssrData: string | null = null;
  ssrClient?: ClientResource;
  ssrSession?: SessionResource | null;

  constructor(
    frontendApi: string,
    options: ClerkOptionsI = {},
    Clerk: BrowserClerkConstructor | undefined | null
  ) {
    this.frontendApi = frontendApi;
    this.options = options;
    this.ClerkConstructor = Clerk;

    this.mode = inBrowser() ? "browser" : "server";

    // TODO: Support SRR for NextJS
    // const ssrDataNode = document.querySelector(`script[data-clerk="SSR"]`);
    // if (ssrDataNode) {
    //   this.ssrData = ssrDataNode.innerHTML;
    //   const parsedData = JSON.parse(this.ssrData);
    //   this.ssrClient = parsedData.client;
    //   this.ssrSession = parsedData.session;
    // }
  }

  loadClerkJS() {
    if (!this.frontendApi) {
      this.throwError(noFrontendApiError);
      return Promise.resolve();
    }

    // Load a fixed Clerk version passed as a parameter
    if (this.ClerkConstructor) {
      window.Clerk = new this.ClerkConstructor(
        this.frontendApi
      ) as BrowserClerkI;

      return window.Clerk.load(this.options)
        .then(() => this.hydrateClerkJS(window.Clerk))
        .catch((err) => this.throwError(err.message || err));
    }

    // Hotload latest ClerkJS from JSDelivr
    return loadScript(this.frontendApi, this.options.scriptUrl)
      .then(() => {
        if (window.Clerk) {
          return window.Clerk.load(this.options);
        }
        throw new Error("Failed to download latest Clerk JS");
      })
      .then(() => this.hydrateClerkJS(window.Clerk))
      .catch((err) => this.throwError(err.message || err));
  }

  // Custom wrapper to throw an error, since we need to apply different handling between
  // production and development builds. In Next.js we can throw a full screen error in
  // development mode. However, in production throwing an error results in an infinite loop
  // as shown at https://github.com/vercel/next.js/issues/6973
  throwError(errorMsg: string) {
    if (process.env.NODE_ENV === "production") {
      console.error(errorMsg);
      return Promise.resolve();
    }
    throw new Error(errorMsg);
  }

  private hydrateClerkJS = async (clerkjs: BrowserClerkI | undefined) => {
    if (!clerkjs) {
      throw new Error("Failed to hydrate latest Clerk JS");
    }

    this.clerkjs = clerkjs;
    this.listeners.forEach((listener) => {
      clerkjs.addListener(listener);
    });

    if (this.preopenSignIn !== null) {
      clerkjs.openSignIn(this.preopenSignIn);
    }

    if (this.preopenSignUp !== null) {
      clerkjs.openSignUp(this.preopenSignUp);
    }

    this.premountSignInNodes.forEach(
      (props: SignInProps, node: HTMLDivElement) => {
        clerkjs.mountSignIn(node, props);
      }
    );

    this.premountSignUpNodes.forEach(
      (props: SignUpProps, node: HTMLDivElement) => {
        clerkjs.mountSignUp(node, props);
      }
    );

    this.premountUserProfileNodes.forEach(
      (props: UserProfileProps, node: HTMLDivElement) => {
        clerkjs.mountUserProfile(node, props);
      }
    );

    this.premountUserButtonNodes.forEach(
      (props: UserProfileProps, node: HTMLDivElement) => {
        clerkjs.mountUserButton(node, props);
      }
    );

    this._loaded = true;

    return this.clerkjs;
  };

  get client() {
    if (this.clerkjs) {
      return this.clerkjs.client;
      // TODO: add ssr condition
    } else {
      return undefined;
    }
  }
  get session() {
    if (this.clerkjs) {
      return this.clerkjs.session;
      // TODO: add ssr condition
    } else {
      return undefined;
    }
  }
  get user() {
    if (this.clerkjs) {
      return this.clerkjs.user;
      // TODO: add ssr condition
    } else {
      return undefined;
    }
  }

  get environment() {
    if (this.clerkjs) {
      return this.clerkjs.environment;
      // TODO: add ssr condition
    } else {
      return undefined;
    }
  }

  setSession = (
    session: SessionResource | string | null,
    beforeEmit?: (session: SessionResource | null) => void | Promise<any>
  ) => {
    if (this.clerkjs) {
      return this.clerkjs.setSession(session, beforeEmit);
    } else {
      return Promise.reject();
    }
  };

  openSignIn = (props?: SignInProps) => {
    if (this.clerkjs && this._loaded) {
      this.clerkjs.openSignIn(props);
    } else {
      this.preopenSignIn = props;
    }
  };

  closeSignIn = () => {
    if (this.clerkjs && this._loaded) {
      this.clerkjs.closeSignIn();
    } else {
      this.preopenSignIn = null;
    }
  };

  openSignUp = (props?: SignUpProps) => {
    if (this.clerkjs && this._loaded) {
      this.clerkjs.openSignUp(props);
    } else {
      this.preopenSignUp = props;
    }
  };

  closeSignUp = () => {
    if (this.clerkjs && this._loaded) {
      this.clerkjs.closeSignUp();
    } else {
      this.preopenSignUp = null;
    }
  };

  mountSignIn = (node: HTMLDivElement, props: SignInProps) => {
    if (this.clerkjs && this._loaded) {
      this.clerkjs.mountSignIn(node, props);
    } else {
      this.premountSignInNodes.set(node, props);
    }
  };

  unmountSignIn = (node: HTMLDivElement) => {
    if (this.clerkjs && this._loaded) {
      this.clerkjs.unmountSignIn(node);
    } else {
      this.premountSignInNodes.delete(node);
    }
  };

  mountSignUp = (node: HTMLDivElement, props: SignUpProps) => {
    if (this.clerkjs && this._loaded) {
      this.clerkjs.mountSignUp(node, props);
    } else {
      this.premountSignUpNodes.set(node, props);
    }
  };

  unmountSignUp = (node: HTMLDivElement) => {
    if (this.clerkjs && this._loaded) {
      this.clerkjs.unmountSignUp(node);
    } else {
      this.premountSignUpNodes.delete(node);
    }
  };

  mountUserProfile = (node: HTMLDivElement, props: UserProfileProps) => {
    if (this.clerkjs && this._loaded) {
      this.clerkjs.mountUserProfile(node, props);
    } else {
      this.premountUserProfileNodes.set(node, props);
    }
  };

  unmountUserProfile = (node: HTMLDivElement) => {
    if (this.clerkjs && this._loaded) {
      this.clerkjs.unmountUserProfile(node);
    } else {
      this.premountUserProfileNodes.delete(node);
    }
  };

  mountUserButton = (node: HTMLDivElement, props: UserButtonProps) => {
    if (this.clerkjs && this._loaded) {
      this.clerkjs.mountUserButton(node, props);
    } else {
      this.premountUserButtonNodes.set(node, props);
    }
  };

  unmountUserButton = (node: HTMLDivElement) => {
    if (this.clerkjs && this._loaded) {
      this.clerkjs.unmountUserButton(node);
    } else {
      this.premountUserButtonNodes.delete(node);
    }
  };

  addListener = (listener: (emission: ListenerEmission) => void) => {
    if (this.clerkjs) {
      this.clerkjs.addListener(listener);
    } else {
      this.listeners.push(listener);
    }
  };

  loadFromServer = (token: string) => {
    if (this.mode === "browser") {
      this.throwError("loadFromServer cannot be called in a browser context.");
    }

    this.ssrData = JSON.stringify({
      client: this.client,
      session: this.session,
      token: token,
    });
  };
}
